/**
 *  项目的配置文件
 */
//因为如果从common.js引入，而request又引入common, common也引入request。会出问题，所以直接在此定义getQueryVariable
const getQueryVariable = (variable) => {
	let query = window.location.search.substring(1);
	let vars = query.split('&');
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split('=');
		if (pair[0] == variable) {
			return pair[1];
		}
	}
	return false;
};

//确保第一时间获取到 id_one值，要不然打开则undefined
const getid_one = () => {
	if (getQueryVariable('storeId')) return 'seller';
	if (getQueryVariable('adminId')) return 'admin';
	return localStorage.identity;
};

const getid_one_1 = () => {
	if (localStorage.identity && localStorage.identity != 'false') {
		if (localStorage.identity === 'admin') {
			return 'admin';
		} else {
			return 'studio';
		}
	} else {
		// TODO【MD】修改IM配置
		if (getQueryVariable('storeId')) return 'studio';
		if (getQueryVariable('adminId')) return 'admin';
	}
};
export const defaultAdminId = 1; //平台超级管理员Id
export const identity = getid_one();

// export const apiUrl = `https://api.dadaoapp.com`; //接口请求地址
// export const apiUrl = `https://api.dadao.icu`; //接口请求地址
export const apiUrl = process.env.VUE_APP_API; //接口请求地址
// export const apiUrl = `http://192.168.1.58:8006`; //接口请求地址
// export const chatUrl = "wss://im.dadaoapp.com"; //客服地址
// export const chatUrl = `wss://im.dadao.icu`; //接口请求地址
// export const chatUrl = `http://192.168.1.58:8112`; //接口请求地址
export const chatUrl =  process.env.VUE_APP_WSS; //客服地址
export const sourceUrl = `https://${getid_one_1()}.${process.env.VUE_APP_URL}/`; //对应的商户后台地址
// export const sourceUrl = `https://${getid_one()}.55sld.com/`; //对应的商户后台地址
export const pcUrl = process.env.VUE_APP_PC; //如果没有pc端的话，这里填空，不是去掉
// export const pcUrl = 'https://www.55sld.com/'  //如果没有pc端的话，这里填空，不是去掉
export const curLang = 'zh'; //当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名

/** copyright *** slodon *** version-v3.9 *** date-2022-12-13 ***主版本v4.3**/
