/*
 * @Author: your name
 * @Date: 2021-01-08 21:09:07
 * @LastEditTime: 2021-01-15 11:36:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/store/index.js
 */
import Vuex from 'vuex';
import { getLocalStorageObject, getLocalStorageStr } from '../utils/common';

export default Vuex.createStore({
	state: {
		loginFlag: getLocalStorageStr('loginFlag') ? true : false,
		memberInfo: getLocalStorageObject('memberInfo'), //用户信息
		storeInfo: getLocalStorageObject('storeInfo'), //店铺信息
		msgVoiceSetting: getLocalStorageStr('msgVoiceSetting') || false, //接收新消息的提示音设置
		isStoreAdmin: getLocalStorageStr('isStoreAdmin'),
	},
	mutations: {
		//更新用户信息
		updateMemberInfo(state, payload) {
			state.memberInfo = payload;
			if (payload.memberId != undefined && payload.memberId) {
				state.loginFlag = true;
			} else {
				state.loginFlag = false;
			}
			localStorage.setItem('memberInfo', JSON.stringify(state.memberInfo));
			localStorage.setItem('loginFlag', state.loginFlag);
		},
		//更新系统配置信息
		updateConfigInfo(state, payload) {
			state.configInfo = payload;
			localStorage.setItem('configInfo', JSON.stringify(state.configInfo));
		},
		//更新店铺信息
		updateStoreInfo(state, payload) {
			state.storeInfo = payload;
			localStorage.setItem('storeInfo', JSON.stringify(state.storeInfo));
		},
		//更新店铺信息
		updateMsgVoiceSetting(state, payload) {
			state.msgVoiceSetting = payload;
			localStorage.setItem('msgVoiceSetting', payload);
		},
		//清空vuex里所有数据
		clearAllData(state) {
			state.memberInfo = {};
			state.loginFlag = false;
			state.storeInfo = {};
			localStorage.removeItem('memberInfo');
			localStorage.removeItem('sld_refresh_token');
			localStorage.removeItem('access_token');
			localStorage.removeItem('loginFlag');
			localStorage.removeItem('storeInfo');
		},
	},
});
