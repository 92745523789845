/*
 * @Author: your name
 * @Date: 2020-12-25 19:22:45
 * @LastEditTime: 2021-01-15 13:50:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/router/index.js
 */
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
	{
		name: 'Home',
		component: () => import(/* webpackChunkName: "home" */ '../views/home/Home'),
		redirect: '/service',
		children: [
			{
				path: '/index',
				name: 'HomeIndex', //首页
				component: () => import(/* webpackChunkName: "home" */ '../views/home/Index'),
			},
			{
				path: '/setting',
				name: 'setting', //聊天设置
				component: () => import(/* webpackChunkName: "home" */ '../views/chatService/setting.vue'),
			},
			{
				path: '/service',
				name: 'service', //对话平台
				component: () => import('../views/chatService/chatPlatform/chatPage'),
			},
			{
				path: '/studio',
				name: 'studio', //工作室对话平台
				component: () => import('../views/chatService/chatPlatform/studioPage'),
			},
			{
				path: '/history',
				name: 'history', //聊天记录
				component: () => import('../views/chatService/chatHistory'),
			},
			{
				path: '/autoReply',
				name: 'autoReply', //欢迎语
				component: () => import('../views/chatService/autoReply.vue'),
			},
			{
				path: '/:pathMatch(.*)*',
				name: 'notFound',
				component: import('../views/home/404.vue'), // 引入 组件
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	next();
});

export default router;
