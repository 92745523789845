import axios from 'axios';
import qs from 'qs';
import { getLocalStorageStr } from './common';
import { apiUrl, storeUrl, identity } from './config';

const instance = axios.create({
	baseURL: apiUrl,
	// timeout: 10000
	timeout: 100000,
});
instance.interceptors.request.use(async (config) => {
	if (config.url.includes(`/oauth/token`)) return config;
	let res = await initRequestHeader(config);
	config.headers = res.headers;
	config.data = res.data;
	return config;
});
export const get = async (url, data = {}) => {
	return new Promise((resolve, reject) => {
		instance.get(url, { params: data }).then(
			(response) => {
				if (response.data.state == 266) {
					localStorage.removeItem('time');
				} else {
					resolve(response.data);
				}
			},
			(err) => {
				reject(err);
			}
		);
	});
};

export const post = async (url, data = {}, requestType = 'urlencoded') => {
	return new Promise((resolve, reject) => {
		instance.post(url, data, { requestType }).then(
			(response) => {
				if (response.data.state == 266) {
					localStorage.removeItem('time');
				} else {
					resolve(response.data);
				}
			},
			(err) => {
				reject(err);
			}
		);
	});
};

/**
 * 初始化请求头和数据
 * @zjf-2020-12-25
 */
const initRequestHeader = async (config) => {
	let { method, url, data = {}, requestType = 'urlencoded' } = config;
	let result = {};
	let headers = {};
	//用户登录状态下，每次更新refresh_token58分钟之后需要更新access_token
	if (getLocalStorageStr('sld_refresh_token')) {
		let cur_time = new Date().getTime();
		if (!getLocalStorageStr('time') || (getLocalStorageStr('time') && cur_time - getLocalStorageStr('time') > 58 * 60 * 1000)) {
			let params = {
				grant_type: 'refresh_token',
				client_id: 'UW1KalUyVnNiR1Z5',
				client_secret: 'U21GMllVQkRiM0I1VW1sbmFIUkFVMnh2Wkc5dQ==',
				refresh_token: getLocalStorageStr('sld_refresh_token'),
			};
			await instance
				.post(`v3/${identity}Login/oauth/token`, qs.stringify(params), {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				})
				.then(
					(response) => {
						if (response.data.state == 200) {
							localStorage.setItem('adminName', response.data.data.adminName);
							localStorage.setItem('sld_token', response.data.data.access_token);
							localStorage.setItem('sld_refresh_token', response.data.data.refresh_token);
							localStorage.setItem('time', new Date().getTime().toString()); //存储refresh_token更新时间
						}
					},
					(err) => {
						console.log('更新access_token出错：', err);
					}
				);
		}
	}

	if (method == 'post') {
		if (requestType == 'urlencoded') {
			headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
			data = qs.stringify(data);
		} else if (requestType == 'json') {
			headers = { 'Content-Type': 'application/json' };
			data = JSON.parse(JSON.stringify(data));
		} else if (requestType == 'form') {
			//form-data
			headers = { 'Content-Type': 'multipart/form-data' };
			let tmp_data = new FormData();
			Object.keys(data).forEach((item) => {
				tmp_data.append(item, data[item]);
			});
			data = tmp_data;
		}
	}

	if (url.indexOf(`v3/${identity}Login/oauth/token`) > -1) {
		headers.Authorization = 'Basic VVcxS2FsRlhVblJoVnpROTpVMjFHTWxsVlFrUmlNMEkxVlcxc2JtRklVa0ZWTW5oMldrYzVkUT09';
	} else {
		let token = getLocalStorageStr('sld_token');
		headers.Authorization = 'Bearer ' + token;
	}

	result = { url, data, headers };
	return result;
};
