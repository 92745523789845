/*
* 语言文件 zh：中文
* */
export const lang_zn = {
    '添加收货地址': '添加收货地址',
    '请输入收货人姓名': '请输入收货人姓名',
    '请选择所在地区': '请选择所在地区',
    '详细地址': '详细地址',
    '请输入详细地址': '请输入详细地址',
    '设为默认地址': '设为默认地址',
    '取 消': '取 消',
    '确 定': '确 定',
    '所在地区': '所在地区',
    // '添加欢迎语': '添加欢迎语',
    '新增欢迎语': '新增欢迎语',
    '序号': '序号',
    '排序': '排序',
    '默认使用': '默认使用',
    '操作': '操作',
    '编辑': '编辑',
    '确定删除此内容吗？': '确定删除此内容吗？',
    '确定': '确定',
    '取消': '取消',
    '删除': '删除',
    '暂无欢迎语': '暂无欢迎语',
    '请输入欢迎语内容': '请输入欢迎语内容',
    '最多输入50字': '最多输入50字',
    '输入排序': '输入排序',
    '请输入排序': '请输入排序',
    '请输入0~255的数字，数值越小，显示越靠前': '请输入0~255的数字，数值越小，显示越靠前',
    '启用': '启用',
    '购物车中还没有商品，赶紧选购吧': '购物车中还没有商品，赶紧选购吧',
    '最新加入的商品': '最新加入的商品',
    '共': '共',
    '件商品': '件商品',
    '去购物车': '去购物车',
    '常见问题': '常见问题',
    '添加常见问题': '添加常见问题',
    '问题': '问题',
    '回答': '回答',
    '显示': '显示',
    '暂无常见问题': '暂无常见问题',
    '请输入常见问题': '请输入常见问题',
    '解决回答': '解决回答',
    '请输入手机号': '请输入手机号',
    '最多输入100字': '最多输入100字',
    '聊天记录': '聊天记录',
    '平台': '平台',
    '商家': '商家',
    '商家:': '商家:',
    '工作室:': '工作室:',
    '请选择商家': '请选择商家',
    '请选择工作室': '请选择工作室',
    '客服:': '客服:',
    '请选择客服': '请选择客服',
    '消息内容': '消息内容',
    '请输入消息内容': '请输入消息内容',
    '聊天日期': '聊天日期',
    '开始日期': '开始日期',
    '结束日期': '结束日期',
    '查询': '查询',
    '搜索最近联系人': '搜索最近联系人',
    '搜索': '搜索',
    '暂无数据': '暂无数据',
    '在线中': '在线中',
    '确定结束与该会员的聊天吗？': '确定结束与该会员的聊天吗？',
    '￥': '￥',
    '订单号': '订单号',
    '已读': '已读',
    '未读': '未读',
    '常用语快捷回复': '常用语快捷回复',
    '暂未设置快捷回复': '暂未设置快捷回复',
    '发送': '发送',
    '在线': '在线',
    '暂无在线客服': '暂无在线客服',
    '请选择访客开启对话': '请选择访客开启对话',
    '用户订单': '用户订单',
    '用户足迹': '用户足迹',
    '店铺商品': '店铺商品',
    '发送链接': '发送链接',
    '暂无订单': '暂无订单',
    '手机号': '手机号',
    '暂无商品': '暂无商品',
    '¥': '¥',
    '折': '折',
    '使用规则': '使用规则',
    '已抢': '已抢',
    '已抢光': '已抢光',
    '立即领取': '立即领取',
    '已领取': '已领取',
    '版权所有': '版权所有',
    '提供技术支持': '提供技术支持',
    '商户-客服助手': '商户-客服助手',
    '平台-客服助手': '平台-客服助手',
    '返回管理后台': '返回管理后台',
    '退出登录': '退出登录',
    '对话平台': '对话平台',
    '快捷回复': '快捷回复',
    '聊天设置': '聊天设置',
    '我们正在努力装修中，敬请期待～': '我们正在努力装修中，敬请期待～',
    '向上滑动浏览更多数据': '向上滑动浏览更多数据',
    '数据加载中...': '数据加载中...',
    '数据加载完毕': '数据加载完毕',
    '账号登录': '账号登录',
    '手机登录': '手机登录',
    '请输入账号/手机号': '请输入账号/手机号',
    '请输入密码': '请输入密码',
    '请输入验证码': '请输入验证码',
    's后获取': 's后获取',
    '获取验证码': '获取验证码',
    '登录': '登录',
    '立即注册': '立即注册',
    '忘记密码': '忘记密码',
    '首页': '首页',
    '个人中心': '个人中心',
    '账户管理': '账户管理',
    '会员信息': '会员信息',
    '账号安全': '账号安全',
    '手机号管理': '手机号管理',
    '邮箱管理': '邮箱管理',
    '登录密码': '登录密码',
    '支付密码': '支付密码',
    '重置密码': '重置密码',
    '收货地址': '收货地址',
    '消息': '消息',
    '请输入关键词': '请输入关键词',
    '我的购物车': '我的购物车',
    '产品分类': '产品分类',
    '您好，欢迎来到': '您好，欢迎来到',
    '退出': '退出',
    '注册': '注册',
    '商城首页': '商城首页',
    '我的订单': '我的订单',
    '待支付订单': '待支付订单',
    '待收货订单': '待收货订单',
    '待评价订单': '待评价订单',
    '我的收藏': '我的收藏',
    '商品收藏': '商品收藏',
    '店铺收藏': '店铺收藏',
    '我的足迹': '我的足迹',
    '我的账户': '我的账户',
    '我的优惠券': '我的优惠券',
    '我的余额': '我的余额',
    '官方客服': '官方客服',
    '服务中心': '服务中心',
    '新消息声音提醒': '新消息声音提醒',
    '请输入解决回答': '请输入解决回答',
    '添加快捷回复语': '添加快捷回复语',
    '快捷回复语': '快捷回复语',
    '暂无快捷回复内容~': '暂无快捷回复内容~',
    '请输入快捷回复语内容': '请输入快捷回复语内容',
    '热门推荐': '热门推荐',
    '已售': '已售',
    '暂时没有数据～': '暂时没有数据～',
    '人购买': '人购买',
    '立即抢购': '立即抢购',
    '查看更多': '查看更多',
    '此处添加【186*340】图片': '此处添加【186*340】图片',
    '此处添加【172*106】图片': '此处添加【172*106】图片',
    '此处添加【187*120】图片': '此处添加【187*120】图片',
    '此处添加【360*560】图片': '此处添加【360*560】图片',
    '此处添加商品': '此处添加商品',
    '请选择商品，数量建议选择3的倍数，最少3个，最多12个': '请选择商品，数量建议选择3的倍数，最少3个，最多12个',
    '已申请数量:': '已申请数量:',
    '立即申请': '立即申请',
    '请上传500*80的图片': '请上传500*80的图片',
    '平台还未设置公告': '平台还未设置公告',
    '平台还未设置资讯': '平台还未设置资讯',
    '请选择供货商，数量建议选择5的倍数，最少5个，最多20个': '请选择供货商，数量建议选择5的倍数，最少5个，最多20个',
    '收货人': '收货人',
    '请输入收货人': '请输入收货人',
    '请输入2~25个字符': '请输入2~25个字符',
    '不能为空': '不能为空',
    '值应为0~255的整数值': '值应为0~255的整数值',
    '排序值不为空': '排序值不为空',
    '修改成功': '修改成功',
    '删除成功': '删除成功',
    '编辑欢迎语回复': '编辑欢迎语回复',
    // '添加欢迎语回复': '添加欢迎语回复',
    '新增欢迎语回复': '新增欢迎语回复',
    '回答不能为空': '回答不能为空',
    '排序值不能为空': '排序值不能为空',
    '编辑常见问题': '编辑常见问题',
    '请先选择会员': '请先选择会员',
    '请先选择客服': '请先选择客服',
    '[表情]': '[表情]',
    '[图片]': '[图片]',
    '[商品]': '[商品]',
    '[订单]': '[订单]',
    '超过最大字符限制': '超过最大字符限制',
    '转接成功': '转接成功',
    '请选择图片类型文件': '请选择图片类型文件',
    '是否要发送剪切板的图片?': '是否要发送剪切板的图片?',
    '提示': '提示',
    '确认': '确认',
    '请输入正确的账号/手机号': '请输入正确的账号/手机号',
    '欢迎语': '欢迎语',
    '交易中心': '交易中心',
    '交易评价/晒单': '交易评价/晒单',
    '关注中心': '关注中心',
    '关注店铺': '关注店铺',
    '客户服务': '客户服务',
    '我的退款': '我的退款',
    '我的退货': '我的退货',
    '会员中心': '会员中心',
    '我的钱包': '我的钱包',
    '账户充值': '账户充值',
    '消息列表': '消息列表',
    '接收设置': '接收设置',
    '编辑快捷语回复': '编辑快捷语回复',
    '添加快捷语回复': '添加快捷语回复',
    '暂无足迹': '暂无足迹',
    '保存': '保存',
    '请输入短信验证码': '请输入短信验证码',
    '也可以点击浏览器地址前面的图标->网站设置->声音，选择允许，下次可以自动接收消息提示音。': '也可以点击浏览器地址前面的图标->网站设置->声音，选择允许，下次可以自动接收消息提示音。',
    '您的浏览器没有开启自动播放声音的功能，点击确认后可接收消息提示音。': '您的浏览器没有开启自动播放声音的功能，点击确认后可接收消息提示音。'
};
